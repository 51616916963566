import {
  encode as base64_encode,
  // decode as base64_decode,
} from 'base-64';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
// import { BoxProps } from '@mui/material';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import RealLogo from '../../assets/logo-real.png';
import MetamaskLogo from '../../assets/logo-metamask-new.png';
import LinkIcon from '../../assets/icon-link-new.png';
// import LoadingButton from '@mui/lab/LoadingButton';
// import Button from '@mui/material/Button';
import { useEffect } from 'react';
import MetaMaskTool from '../../libs/metamask';
// import { toast } from 'react-toastify';

const MainBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxSizing: 'border-box',
  paddingTop: '30px',
  width: '100%',
  height: '100%',
});

const TitleContainer = styled(Box)({
  width: '100%',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '104px'
})

const NavTitle = styled(Typography)({
  fontSize: '18px',
  color: '#050505',
  lineHeight: '18px',
  letterSpacing: '1px',
  fontFamily: 'FuBlod',
});

const IconsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '64px',
})

const LogoBox = styled(Box)({
  width: '80px',
  height: '80px',
  borderRadius: '16px',
  overflow: 'hidden',
});

const IconBox = styled(Box)({
  width: '44px',
  height: '44px',
  margin: '0 24px',
});

const Title = styled(Typography)({
  fontSize: '18px',
  color: '#050505',
  lineHeight: '25px',
  fontFamily: 'FuBlod',
  letterSpacing: '1px',
  marginBottom: '4px',
});

const SubTitle = styled(Typography)({
  fontSize: '12px',
  color: '#8A8F97',
  lineHeight: '15px',
  fontFamily: 'FuMiddle',
  marginBottom: '32px',
});

// interface ConnecttButtonProps extends BoxProps {
//   isConnecting: boolean,
// }

// const ConnectButton = styled(Box, {
//   shouldForwardProp: (prop) => prop !== 'isConnecting',
// })<ConnecttButtonProps>(({isConnecting}) => ({
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   width: '287px',
//   height: '48px',
//   fontSize: '14px',
//   color: '#FFFFFF',
//   padding: '0',
//   borderRadius: '25px',
//   textTransform: 'none',
//   fontFamily: 'FuBlod',
//   backgroundColor: '#752FFF',
//   opacity: isConnecting ? '0.36' : '1',
// }));

const Sign = (): JSX.Element => {
  // const [isConnecting, setIsConnecting] = useState(false);
  // const signText = `Join Me On REAL & Let's Enjoy 3D NFT Face Swapping In Web3.`;

  useEffect(() => {
    const signAuto = async () => {
      // setIsConnecting(true);
      try {
        await MetaMaskTool.instance.connect();
        const data = JSON.stringify({
          'wallet_address': MetaMaskTool.instance.accountAddress,
          'error': '',
        });
        // setIsConnecting(false);
        // @ts-ignore
        window.location = `https://www.realapp.xyz/${base64_encode(data)}`;
      } catch (error) {
        // setIsConnecting(false);
        // @ts-ignore
        window.location = `https://www.realapp.xyz/${base64_encode(JSON.stringify({wallet_address: '', error, }))}`
      }
    }

    const start = async () => {
      // await MetaMaskTool.instance.connect();
      await signAuto();
    }

    start();
  }, []);

  // const sign = async () => {
  //   if (isConnecting) return
  //   setIsConnecting(true)
  //   try {
  //     await MetaMaskTool.instance.connect();
  //   } catch (error) {
  //   }

  //   try {
  //     const signResult = await MetaMaskTool.instance.sign(signText);
  //     const data = JSON.stringify({
  //       'wallet_address': MetaMaskTool.instance.accountAddress,
  //       'sign_data': signResult,
  //     });
  //     setIsConnecting(false);
  //     // @ts-ignore
  //     window.location = `https://www.realapp.xyz/${base64_encode(data)}`;
  //   } catch (error) {
  //     setIsConnecting(false);
  //     toast.error('Sign Failed.');
  //   }
  // }

  return (
    <>
      <Container fixed sx={{ backgroundColor: '#FFFFFF', height: '100vh', padding: 0, }}>
        <MainBox>
          <TitleContainer>
            <NavTitle>
              Connect Wallet
            </NavTitle>
          </TitleContainer>

          <IconsContainer>
            <LogoBox>
              <img src={RealLogo} alt="REAl" style={{width: '100%', height: '100%', display: 'block',}} />
            </LogoBox>
            <IconBox>
              <img src={LinkIcon} alt="Link" style={{width: '100%', height: '100%', display: 'block',}} />
            </IconBox>
            <LogoBox>
              <img src={MetamaskLogo} alt="Metamask" style={{width: '100%', height: '100%', display: 'block',}} />
            </LogoBox>
          </IconsContainer>

          <Title>
            Connect wallet to REAL
          </Title>
          <SubTitle>
            Ethereum Mainnet
          </SubTitle>

          {/* <ConnectButton isConnecting={isConnecting} onClick={sign}>
            {
              isConnecting ? 'Connecting…' : 'Connect'
            }
          </ConnectButton> */}
        </MainBox>
      </Container>
    </>
  );
}

export default Sign;
